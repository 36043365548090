import request from '@/utils/request'
import { Message, MessageBox } from 'element-ui'
import axios from 'axios';
import * as func from "@/public/base_func";

export function download(url: string, params: any, filename: string, blobOption: BlobPropertyBag = {
  type: 'application/vnd.ms-excel'
}) {
  //Message.warning('导出数据中')

  return /*request*/axios.get(url, {
    params: params,
    responseType: 'arraybuffer',
    auth: {
      username: 'super',
      password: func.backEndAuthDynamicPassword()//'super123'
    },
    timeout: 180000,
    maxRedirects: 1
  }).then((r) => {
    const content = r.data
    const blob = new Blob([content], blobOption)
    if ('download' in document.createElement('a')) {
      const elink = document.createElement('a')
      elink.download = filename
      elink.style.display = 'none'
      elink.href = URL.createObjectURL(blob)
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href)
      document.body.removeChild(elink)
      //Message.success('导出成功')
    }
  }).catch((r) => {
    console.error(r)
    Message.error('导出失败，请检查网络。')
  })

}

export async function downloadAsBlob(url: string, params: any, blobOption: BlobPropertyBag = {}): Promise<Blob> {
  const resp = await /*request*/axios.get(url, {
    params,
    responseType: 'arraybuffer',
    auth: {
      username: "super",
      password: func.backEndAuthDynamicPassword()//'super123'
    }
  });

  return new Blob([resp.data], blobOption);
}
